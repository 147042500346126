* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.App {
  background-color: #2d2a2e;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  padding-top: 60px; /* Adjust this value to match your navbar's height */
}


.navbar {
  background-color: #221f22;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 3px solid #000000;
  z-index: 1000; /* Ensure navbar stays above other content */
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow items to wrap if necessary */
}

.nav-item {
  background: none;
  border: none;
  color: #fcfcfa;
  text-align: center;
  padding: 15px 20px;
  text-decoration: none;
  font-size: 18px;
  transition: 0.1s;
  cursor: pointer;
}

/* Hover and active styles remain the same */
.nav-item:hover {
  color: #ffd866;
}

.nav-item.active {
  color: #ffd866;
}

/* Media query for screens less than 600px wide */
@media (max-width: 600px) {

  .App {
    padding-top: 0px; /* Adjust this value to match your navbar's height */
  }

  .nav-item {
    font-size: 15px;
    padding: 10px 10px;
  }

  .nav-links {
    flex-direction: row; /* Stack items vertically */
    align-items: center;
    justify-content: space-between;
  }

  .navbar {
    position: fixed; /* Allow the navbar to flow with content */
  }
}